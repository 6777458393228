import React from 'react'
import { Button, Text } from 'theme-ui'
import Hide from '../svg/Hide'
import Show from '../svg/Show'

interface Props {
  content: string
  isOpen: boolean
  toggle: () => void
}

const SessionToggle = ({ content, isOpen, toggle }: Props) => (
  <Button
    variant="naked"
    onClick={() => toggle()}
    sx={{
      display: 'flex',
      mb: 3,
      alignItems: 'center',
      opacity: 0.5,
      ':hover': {
        opacity: 1
      }
    }}
  >
    {isOpen ? <Hide /> : <Show />}
    <Text sx={{ color: 'action', ml: 1, fontSize: '16px' }}>
      {isOpen ? 'Hide' : 'Show'} {content}
    </Text>
  </Button>
)

export default SessionToggle
