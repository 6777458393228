import React from 'react'
import { Flex } from 'theme-ui'
import Logo from '../svg/Logo'

const HomePageHeader = () => {
  return (
    <Flex px={5} py={4} sx={{ width: '100%' }}>
      <Logo />
    </Flex>
  )
}

export default HomePageHeader
