import { gql } from '@apollo/client'

const SAVE_SESSION_STATUS = gql`
  mutation saveSessionStatus($sessionId: uuid!, $skipped: Boolean) {
    insert_sessionProgress_one(
      object: { session_id: $sessionId, skipped: $skipped }
      on_conflict: {
        constraint: sessionProgress_user_id_session_id_key
        update_columns: [skipped]
      }
    ) {
      skipped
    }
  }
`

export default SAVE_SESSION_STATUS
