import React from 'react'
import { Flex, Box, Heading, Text } from 'theme-ui'
import SessionCompleted from '../svg/SessionCompleted'
import SessionNotStarted from '../svg/SessionNotStarted'
import SessionSkipped from '../svg/SessionSkipped'
import SessionStarted from '../svg/SessionStarted'
import { SessionStatus, SessionWithStatus } from '../types'
import SessionControls from './SessionControls'
import Mins20 from '../svg/Mins20'
import Mins5 from '../svg/Mins5'
import Mins10 from '../svg/Mins10'
import Mins15 from '../svg/Mins15'
import Mins30 from '../svg/Mins30'
import Mins45 from '../svg/Mins45'
import Mins60 from '../svg/Mins60'
import { SkipSessionArgs } from './Sessions'
import { useMutation } from '@apollo/client'
import SAVE_SESSION_STATUS from '../gql/SAVE_SESSION_STATUS'

const getProgressIcon = (status: SessionStatus) => {
  switch (status) {
    case SessionStatus.Completed:
      return <SessionCompleted />
    case SessionStatus.NotStarted:
      return <SessionNotStarted />
    case SessionStatus.Skipped:
      return <SessionSkipped />
    case SessionStatus.Started:
      return <SessionStarted />
  }
}

const getDurationIcon = (duration: number) => {
  switch (duration) {
    case 5:
      return <Mins5 />
    case 10:
      return <Mins10 />
    case 15:
      return <Mins15 />
    case 20:
      return <Mins20 />
    case 30:
      return <Mins30 />
    case 45:
      return <Mins45 />
    case 60:
      return <Mins60 />
  }
}

interface Props {
  session: SessionWithStatus
}

const SessionPreview = ({ session }: Props) => {
  const [saveSessionStatus, { loading }] = useMutation(SAVE_SESSION_STATUS, {
    refetchQueries: ['GetSessions']
  })

  const skipSession = ({ sessionId, skipped }: SkipSessionArgs) => {
    saveSessionStatus({
      variables: {
        sessionId,
        skipped
      }
    })
  }

  const { id, title, slug, preview, duration, status } = session

  return (
    <Flex
      key={slug}
      sx={{
        pb: 5,
        mb: 5,
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: 'border',
        '&:last-child': {
          borderBottomWidth: 0,
          mb: 0
        }
      }}
    >
      <Flex sx={{ width: '120px', mr: 5 }}>
        <Box sx={{ width: '120px' }}>{getProgressIcon(status)}</Box>
      </Flex>
      <Flex sx={{ flexDirection: 'column', flex: 1 }}>
        <Heading variant="sessionHeading" as="h3" mb={1}>
          {title}
        </Heading>
        <Text mb={3}>{preview}</Text>
        <Flex sx={{ alignItems: 'baseline' }}>
          <SessionControls
            id={id}
            slug={slug}
            status={status}
            loading={loading}
            skipSession={skipSession}
          />
          <Flex
            sx={{
              alignItems: 'center',
              justifyContent: 'flex-end',
              flex: 1
            }}
          >
            <Text
              sx={{
                pr: 1,
                color: 'duration',
                fontSize: '16px',
                lineHeight: '24px'
              }}
            >{`${duration} mins`}</Text>
            {getDurationIcon(duration)}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default SessionPreview
