import React, { MouseEventHandler, ReactNode } from 'react'
import {
  Button as BaseButton,
  Link,
  ThemeUIStyleObject,
  Spinner
} from 'theme-ui'
import { Link as RouterLink } from 'react-router-dom'

import CircleTick from '../svg/CircleTick'

export enum Priority {
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY',
  Tertiary = 'TERTIARY'
}

export enum ButtonStatus {
  Disabled = 'DISABLED',
  Loading = 'LOADING',
  Done = 'DONE'
}

interface ButtonContentArgs {
  children: ReactNode
  buttonStatus: ButtonStatus
  priority: Priority
}

const getButtonContent = ({
  children,
  priority,
  buttonStatus
}: ButtonContentArgs) => {
  switch (buttonStatus) {
    case ButtonStatus.Loading:
      return (
        <Spinner
          color={priority === Priority.Primary ? 'white' : '#97133E'}
          size={12}
          strokeWidth={2}
        />
      )
    case ButtonStatus.Done:
      return <CircleTick />
    default:
      return children
  }
}

interface Props {
  small?: boolean
  children: ReactNode
  sx?: ThemeUIStyleObject
  priority?: Priority
  buttonStatus?: ButtonStatus
  onClick?: MouseEventHandler<HTMLButtonElement>
  href?: string
}

const Button = ({
  small,
  onClick,
  href,
  sx,
  buttonStatus,
  priority = Priority.Primary,
  children
}: Props) => {
  const style = {
    cursor: 'pointer',
    borderWidth: small ? '1px' : '2px',
    borderColor: priority === Priority.Secondary ? 'action' : 'transparent',
    borderStyle: 'solid',
    fontWeight: 500,
    fontSize: small ? 0 : 2,
    lineHeight: small ? '12px' : '24px',
    textDecoration: 'none',
    color: priority === Priority.Primary ? 'white' : 'action',
    backgroundColor: priority === Priority.Primary ? 'action' : 'transparent',
    px: 3,
    py: 1,
    borderRadius: '4px'
  }

  return href ? (
    <Link as={RouterLink} to={href} sx={{ ...style, ...sx }}>
      {getButtonContent({ children, buttonStatus, priority })}
    </Link>
  ) : (
    <BaseButton
      onClick={onClick}
      sx={{ ...style, ...sx }}
      disabled={buttonStatus === ButtonStatus.Disabled}
    >
      {getButtonContent({ children, buttonStatus, priority })}
    </BaseButton>
  )
}

export default Button
