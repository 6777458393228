import { Theme } from 'theme-ui'

const wheat = '#FCF9F6'
const navy = '#2D4757'
const maroon = '#97133E'
const malibu = '#76CFFA'
const grass = '#6BD66F'
const bud = '#6CB36E'
const forest = '#004202'
const piggy = '#EFDCE2'
const sand = '#FEC770'
const bloodOrange = '#DE3200'
const mediumGrey = '#B4BBBD'
const darkGrey = '#666666'

const theme: Theme = {
  space: [0, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80, 88, 96],
  fontSizes: [12, 14, 16, 18, 20, 24, 32, 48, 64],
  colors: {
    text: navy,
    primary: navy,
    secondary: maroon,
    action: maroon,
    background: wheat,
    editorBackground: navy,
    notStarted: malibu,
    completed: grass,
    inProgress: sand,
    skipped: bloodOrange,
    border: mediumGrey,
    passBackground: bud,
    passText: forest,
    failBackground: piggy,
    failText: maroon,
    duration: darkGrey
  },
  fonts: {
    body: 'freight-sans-pro, sans-serif',
    heading: 'adelle, serif'
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125
  },
  fontWeights: {
    body: 400,
    heading: 400
  },
  text: {
    h1: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      color: 'primary',
      fontSize: 6,
      my: 12
    },
    h2: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      color: 'primary',
      fontSize: 5,
      my: 3
    },
    h3: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      color: 'primary',
      fontSize: 4,
      my: 3
    },
    p: {
      mb: 2,
      '&:last-child': {
        mb: 0
      }
    },
    smallHeading: {
      fontFamily: 'body',
      lineHeight: 1.5,
      fontWeight: 700,
      fontSize: 4,
      mb: 1
    },
    testing: {
      fontSize: 6
    },
    sessionHeading: {
      fontSize: 5
    }
  },
  links: {
    bold: {
      fontWeight: 500,
      textDecoration: 'none',
      color: 'action'
    },
    primaryButton: {
      cursor: 'pointer',
      borderWidth: '2px',
      borderColor: 'transparent',
      borderStyle: 'solid',
      fontWeight: 500,
      fontSize: 2,
      textDecoration: 'none',
      color: 'white',
      backgroundColor: 'action',
      px: 3,
      py: 1,
      borderRadius: '4px'
    }
  },
  buttons: {
    naked: {
      backgroundColor: 'transparent',
      color: 'maroon',
      width: 'fit-content',
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '24px',
      p: 0,
      cursor: 'pointer'
    },
    minorPrimary: {
      cursor: 'pointer',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'maroon',
      bg: 'background',
      color: 'maroon',
      fontSize: '12px',
      lineHeight: '12px',
      fontWeight: 400,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      mt: 2,
      borderRadius: '4px',
      px: 3,
      py: 1
    },
    primaryButton: {
      cursor: 'pointer',
      borderWidth: '2px',
      borderColor: 'transparent',
      borderStyle: 'solid',
      fontWeight: 500,
      fontSize: 2,
      textDecoration: 'none',
      color: 'white',
      backgroundColor: 'action',
      px: 3,
      py: 1,
      borderRadius: '4px'
    },
    secondaryButton: {
      cursor: 'pointer',
      borderWidth: '2px',
      borderColor: 'maroon',
      borderStyle: 'solid',
      fontWeight: 500,
      fontSize: 2,
      textDecoration: 'none',
      color: 'maroon',
      backgroundColor: 'wheat',
      px: 3,
      py: 1,
      borderRadius: '4px'
    },
    tertiaryButton: {
      cursor: 'pointer',
      borderWidth: '2px',
      borderColor: 'transparent',
      borderStyle: 'solid',
      fontWeight: 500,
      fontSize: 2,
      textDecoration: 'none',
      color: 'action',
      backgroundColor: 'transparent',
      px: 3,
      py: 1
    }
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      fontSize: 4
    },
    a: {
      textDecoration: 'none',
      color: 'action'
    }
  }
}

export default theme
