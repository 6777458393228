import { gql } from '@apollo/client'

const GET_USERS = gql`
  query {
    users {
      gh_name
      gh_pic
    }
  }
`

export default GET_USERS
