import React from 'react'

const Mins20 = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="9" stroke="#666666" strokeWidth="2" />
      <mask id="path-2-inside-1" fill="white">
        <path d="M16.25 10C16.25 9.17924 16.0883 8.36651 15.7742 7.60823C15.4602 6.84994 14.9998 6.16095 14.4194 5.58058C13.8391 5.00022 13.1501 4.53984 12.3918 4.22575C11.6335 3.91166 10.8208 3.75 10 3.75L10 10H16.25Z" />
      </mask>
      <path
        d="M16.25 10C16.25 9.17924 16.0883 8.36651 15.7742 7.60823C15.4602 6.84994 14.9998 6.16095 14.4194 5.58058C13.8391 5.00022 13.1501 4.53984 12.3918 4.22575C11.6335 3.91166 10.8208 3.75 10 3.75L10 10H16.25Z"
        fill="#666666"
        stroke="#666666"
        strokeWidth="4"
        mask="url(#path-2-inside-1)"
      />
      <mask id="path-3-inside-2" fill="white">
        <path d="M15.4127 13.125C15.823 12.4142 16.0894 11.6295 16.1965 10.8158C16.3037 10.002 16.2495 9.17518 16.037 8.38238C15.8246 7.58959 15.4581 6.84639 14.9585 6.19524C14.4588 5.54409 13.8358 4.99772 13.125 4.58734L10 10L15.4127 13.125Z" />
      </mask>
      <path
        d="M15.4127 13.125C15.823 12.4142 16.0894 11.6295 16.1965 10.8158C16.3037 10.002 16.2495 9.17518 16.037 8.38238C15.8246 7.58959 15.4581 6.84639 14.9585 6.19524C14.4588 5.54409 13.8358 4.99772 13.125 4.58734L10 10L15.4127 13.125Z"
        fill="#666666"
        stroke="#666666"
        strokeWidth="4"
        mask="url(#path-3-inside-2)"
      />
    </svg>
  )
}

export default Mins20
