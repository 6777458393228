export interface Output {
  text: string
  isError: boolean
}

export interface User {
  gh_name: string
  gh_pic: string
}

export interface UsersResult {
  users: User[]
}

export interface TestProgress {
  status: string
  tests_passed: number
}

export interface SessionTest {
  test_number: number
  progresses: TestProgress[]
}

export interface SessionProgress {
  skipped: boolean
}

export interface SessionDependency {
  depends_on_session: string
}
export interface Session {
  id: string
  slug: string
  title: string
  preview: string
  duration: number
  tests: SessionTest[]
  sessionProgresses: SessionProgress[]
  dependsOn: SessionDependency[]
}

export interface SessionWithStatus extends Session {
  status: SessionStatus
}

export interface SessionsResult {
  sessions: Session[]
}

export interface TestVariables {
  id: string
}

export interface Progress {
  code: string
  status: ProgressStatus
  tests_passed: number | null
}

export interface Test {
  id: string
  title: string
  preview: string
  description: string
  initial_code: string
  test_code: string
  test_number: number
  progresses: Progress[]
}

export interface TestResult {
  tests_by_pk: Test
}

export enum EvalMessageType {
  'RUN_BEGIN' = 'RUN_BEGIN',
  'SUITE_BEGIN' = 'SUITE_BEGIN',
  'SUITE_END' = 'SUITE_END',
  'TEST_PENDING' = 'TEST_PENDING',
  'TEST_PASS' = 'TEST_PASS',
  'TEST_FAIL' = 'TEST_FAIL',
  'RUN_END' = 'RUN_END',
  'CONSOLE_LOG' = 'CONSOLE_LOG',
  'TEST_ARGS' = 'TEST_ARGS',
  'TEST_BEGIN' = 'TEST_BEGIN',
  'TEST_END' = 'TEST_END'
}

export interface EvalRunBegin {
  type: EvalMessageType.RUN_BEGIN
}

export interface EvalRunEnd {
  type: EvalMessageType.RUN_END
}

export interface EvalSuiteBegin {
  type: EvalMessageType.SUITE_BEGIN
  suite: string
}
export interface EvalSuiteEnd {
  type: EvalMessageType.SUITE_END
  suite: string
}

export interface EvalTestBegin {
  type: EvalMessageType.TEST_BEGIN
  test: string
}
export interface EvalTestPending {
  type: EvalMessageType.TEST_PENDING
  test: string
}
export interface EvalTestPass {
  type: EvalMessageType.TEST_PASS
  test: string
}

export interface EvalTestFail {
  type: EvalMessageType.TEST_FAIL
  test: string
  error: any
}

export interface EvalTestEnd {
  type: EvalMessageType.TEST_END
}

export interface EvalConsoleLog {
  type: EvalMessageType.CONSOLE_LOG
  message: string
}

export interface EvalTestArgs {
  type: EvalMessageType.TEST_ARGS
  args: string
}

export type EvalMessage =
  | EvalRunBegin
  | EvalSuiteBegin
  | EvalSuiteEnd
  | EvalTestBegin
  | EvalTestEnd
  | EvalTestPending
  | EvalTestPass
  | EvalTestFail
  | EvalRunEnd
  | EvalConsoleLog
  | EvalTestArgs

export interface WorkerSuite {
  title: string
}

export interface WorkerTest {
  title: string
  duration?: number
  speed?: string
}

export interface FailedWorkerTest {
  title: string
  duration?: number
  speed?: string
  actual: any
  expected: any
}

export interface SuiteReport {
  title: string
  tests: TestReport[]
}

export interface TestReport {
  title: string
  args?: any[]
  logs: string[]
  duration?: number
  speed?: string
  actual?: any
  expected?: any
  pass?: boolean
}

export enum ProgressStatus {
  NotStarted = 'NOT_STARTED',
  Started = 'STARTED',
  Completed = 'COMPLETED',
  Skipped = 'SKIPPED',
  Collapsed = 'COLLAPSED'
}

export enum SessionStatus {
  NotStarted = 'NOT_STARTED',
  Started = 'STARTED',
  Completed = 'COMPLETED',
  Skipped = 'SKIPPED'
}

export enum SaveStatus {
  SAVING = 'SAVING',
  SAVED = 'SAVED',
  SAVE_FAILED = 'SAVE_FAILED',
  NOTHING = 'NOTHING'
}
