import React, { useState } from 'react'
import { Flex, Heading } from 'theme-ui'
import Header from './Header'
import GET_SESSIONS from '../gql/GET_SESSIONS'
import {
  SessionsResult,
  Session,
  SessionStatus,
  ProgressStatus,
  SessionWithStatus
} from '../types'
import { useQuery } from '@apollo/client'
import SessionPreview from './SessionPreview'
import SessionToggle from './SessionToggle'

const getSessionStatus = (session: Session) => {
  if (session.sessionProgresses?.[0]?.skipped) {
    return SessionStatus.Skipped
  }

  const testStatuses = session.tests.map(
    test => test.progresses?.[0]?.status ?? ProgressStatus.NotStarted
  )

  const statusAcc = testStatuses.reduce(
    ({ allNotStarted, allSkippedOrPassed }, item) => {
      if (item === ProgressStatus.NotStarted) {
        return {
          allNotStarted,
          allSkippedOrPassed: false
        }
      }

      if (
        [
          ProgressStatus.Skipped,
          ProgressStatus.Completed,
          ProgressStatus.Collapsed
        ].includes(item as ProgressStatus)
      ) {
        return {
          allNotStarted: false,
          allSkippedOrPassed
        }
      }

      return {
        allNotStarted: false,
        allSkippedOrPassed: false
      }
    },
    {
      allNotStarted: true,
      allSkippedOrPassed: true
    }
  )

  if (statusAcc.allNotStarted) {
    return SessionStatus.NotStarted
  }

  if (statusAcc.allSkippedOrPassed) {
    return SessionStatus.Completed
  }

  return SessionStatus.Started
}

const mungeSessions = (sessions: Session[]): SessionWithStatus[] => {
  return [...sessions]
    .sort((a, b) => {
      return b.dependsOn.some(item => item.depends_on_session === a.id) ? -1 : 0
    })
    .map(session => {
      const status = getSessionStatus(session)
      return {
        ...session,
        status
      }
    })
}

export interface SkipSessionArgs {
  sessionId: string
  skipped: boolean
}

const Sessions = () => {
  const [completedOpen, setCompletedOpen] = useState(false)
  const [upcomingOpen, setUpcomingOpen] = useState(false)
  const { data, loading } = useQuery<SessionsResult>(GET_SESSIONS)

  interface CollatedSessions {
    skippedCompleted: SessionWithStatus[]
    current: SessionWithStatus[]
    next: SessionWithStatus[]
  }

  const mungedSessions = mungeSessions(
    data?.sessions ?? []
  ).reduce<CollatedSessions>(
    ({ skippedCompleted, current, next }, session) => {
      if (
        [SessionStatus.Completed, SessionStatus.Skipped].includes(
          session.status
        )
      ) {
        return {
          skippedCompleted: [...skippedCompleted, session],
          current,
          next
        }
      }

      if (
        (session.status === SessionStatus.NotStarted && !current.length) ||
        session.status === SessionStatus.Started
      ) {
        return {
          skippedCompleted,
          current: [...current, session],
          next
        }
      }

      return {
        skippedCompleted,
        current,
        next: [...next, session]
      }
    },
    {
      skippedCompleted: [],
      current: [],
      next: []
    }
  )

  return (
    <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
      <Header />
      <Flex sx={{ flexDirection: 'column', width: '800px' }}>
        <Heading as="h1" variant="h1" sx={{ textAlign: 'center' }}>
          Your sessions
        </Heading>

        {loading ? (
          <Flex>Loading</Flex>
        ) : (
          <>
            {completedOpen
              ? mungedSessions.skippedCompleted.map(session => (
                  <SessionPreview key={session.id} session={session} />
                ))
              : null}
            {mungedSessions.skippedCompleted.length ? (
              <SessionToggle
                isOpen={completedOpen}
                toggle={() => setCompletedOpen(isOpen => !isOpen)}
                content="completed and skipped sessions"
              />
            ) : null}
            {mungedSessions.current.map(session => (
              <SessionPreview key={session.id} session={session} />
            ))}
            {mungedSessions.next.length ? (
              <SessionToggle
                toggle={() => setUpcomingOpen(isOpen => !isOpen)}
                isOpen={upcomingOpen}
                content="upcoming sessions"
              />
            ) : null}
            {upcomingOpen
              ? mungedSessions.next.map(session => (
                  <SessionPreview key={session.id} session={session} />
                ))
              : null}
          </>
        )}
      </Flex>
    </Flex>
  )
}

export default Sessions
