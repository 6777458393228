import React from 'react'
import { Flex } from 'theme-ui'
import HomePageHeader from './HomePageHeader'

const Home = () => {
  return (
    <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
      <HomePageHeader />
      <Flex sx={{ flexDirection: 'column', width: '800px' }}>
        Stuff goes here - test deploy
      </Flex>
    </Flex>
  )
}

export default Home
