import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ThemeProvider } from 'theme-ui'
import Home from './Home'
import '@csstools/normalize.css'
import Course from './Course'
import Syllabus from './Syllabus'
import { ApolloProvider } from '@apollo/client'
import theme from '../theme'
import { createApolloClient } from '../createApolloClient'
import Sessions from './Sessions'
// import SessionRouter from './generated/SessionRouter'

const App = () => {
  const client = createApolloClient()

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/sessions" component={Sessions} />
          <Route exact path="/course" component={Course} />
          <Route exact path="/syllabus" component={Syllabus} />
          {/* <SessionRouter /> */}
        </Switch>
      </ThemeProvider>
    </ApolloProvider>
  )
}

export default App
