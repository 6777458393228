import React from 'react'
import { SessionStatus } from '../types'
import { SkipSessionArgs } from './Sessions'
import Button, { ButtonStatus, Priority } from './Button'

interface Props {
  id: string
  slug: string
  status: SessionStatus
  loading: boolean
  skipSession: (args: SkipSessionArgs) => void
}

const SessionControls = ({ id, slug, status, skipSession, loading }: Props) => {
  switch (status) {
    case SessionStatus.NotStarted:
      return (
        <>
          <Button
            href={`/sessions/${slug}`}
            priority={Priority.Primary}
            sx={{ mr: 3 }}
          >
            Start session
          </Button>
          <Button
            priority={Priority.Tertiary}
            buttonStatus={loading ? ButtonStatus.Loading : undefined}
            onClick={event => {
              event.preventDefault()

              skipSession({ sessionId: id, skipped: true })
            }}
          >
            Skip session
          </Button>
        </>
      )

    case SessionStatus.Skipped:
      return (
        <Button
          priority={Priority.Primary}
          buttonStatus={loading ? ButtonStatus.Loading : undefined}
          onClick={event => {
            event.preventDefault()

            skipSession({ sessionId: id, skipped: false })
          }}
        >
          Unskip session
        </Button>
      )

    case SessionStatus.Completed:
      return (
        <Button
          href={`/sessions/${slug}`}
          priority={Priority.Primary}
          sx={{ mr: 3 }}
        >
          View session
        </Button>
      )

    case SessionStatus.NotStarted:
      return (
        <>
          <Button
            href={`/sessions/${slug}`}
            priority={Priority.Primary}
            sx={{ mr: 3 }}
          >
            Continue
          </Button>
          <Button
            priority={Priority.Tertiary}
            buttonStatus={loading ? ButtonStatus.Loading : undefined}
            onClick={event => {
              event.preventDefault()

              skipSession({ sessionId: id, skipped: true })
            }}
          >
            Skip session
          </Button>
        </>
      )
  }
}

export default SessionControls
