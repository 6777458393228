import { gql } from '@apollo/client'

const GET_SESSIONS = gql`
  query GetSessions {
    sessions {
      id
      preview
      slug
      title
      duration
      tests {
        test_number
        progresses {
          status
          tests_passed
        }
      }
      sessionProgresses {
        skipped
      }
      dependsOn {
        depends_on_session
      }
    }
  }
`

export default GET_SESSIONS
