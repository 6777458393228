import React from 'react'

const Mins30 = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="9" stroke="#666666" strokeWidth="2" />
      <mask id="path-2-inside-1" fill="white">
        <path d="M16.25 10C16.25 9.17924 16.0883 8.36651 15.7742 7.60823C15.4602 6.84994 14.9998 6.16095 14.4194 5.58058C13.8391 5.00022 13.1501 4.53984 12.3918 4.22575C11.6335 3.91166 10.8208 3.75 10 3.75L10 10H16.25Z" />
      </mask>
      <path
        d="M16.25 10C16.25 9.17924 16.0883 8.36651 15.7742 7.60823C15.4602 6.84994 14.9998 6.16095 14.4194 5.58058C13.8391 5.00022 13.1501 4.53984 12.3918 4.22575C11.6335 3.91166 10.8208 3.75 10 3.75L10 10H16.25Z"
        fill="#666666"
        stroke="#666666"
        strokeWidth="4"
        mask="url(#path-2-inside-1)"
      />
      <mask id="path-3-inside-2" fill="white">
        <path d="M10 16.25C10.8208 16.25 11.6335 16.0883 12.3918 15.7742C13.1501 15.4602 13.8391 14.9998 14.4194 14.4194C14.9998 13.8391 15.4602 13.1501 15.7742 12.3918C16.0883 11.6335 16.25 10.8208 16.25 10L10 10L10 16.25Z" />
      </mask>
      <path
        d="M10 16.25C10.8208 16.25 11.6335 16.0883 12.3918 15.7742C13.1501 15.4602 13.8391 14.9998 14.4194 14.4194C14.9998 13.8391 15.4602 13.1501 15.7742 12.3918C16.0883 11.6335 16.25 10.8208 16.25 10L10 10L10 16.25Z"
        fill="#666666"
        stroke="#666666"
        strokeWidth="4"
        mask="url(#path-3-inside-2)"
      />
    </svg>
  )
}

export default Mins30
