import React from 'react'

const Mins10 = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="9" stroke="#666666" strokeWidth="2" />
      <mask id="path-2-inside-1" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 10L15.4127 6.875C15.4211 6.88958 15.4294 6.90419 15.4377 6.91884C15.1607 6.42993 14.8184 5.97958 14.4194 5.58058C13.8391 5.00022 13.1501 4.53984 12.3918 4.22575C11.6335 3.91166 10.8208 3.75 10 3.75V10Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 10L15.4127 6.875C15.4211 6.88958 15.4294 6.90419 15.4377 6.91884C15.1607 6.42993 14.8184 5.97958 14.4194 5.58058C13.8391 5.00022 13.1501 4.53984 12.3918 4.22575C11.6335 3.91166 10.8208 3.75 10 3.75V10Z"
        fill="#666666"
      />
      <path
        d="M15.4127 6.875L17.1447 5.875L16.1447 4.14295L14.4127 5.14295L15.4127 6.875ZM10 10H8V13.4641L11 11.7321L10 10ZM15.4377 6.91884L13.6977 7.90481L17.1778 5.93289L15.4377 6.91884ZM14.4194 5.58058L15.8336 4.16637L15.8336 4.16637L14.4194 5.58058ZM12.3918 4.22575L13.1571 2.37799L13.1571 2.37799L12.3918 4.22575ZM10 3.75V1.75H8V3.75H10ZM14.4127 5.14295L9 8.26795L11 11.7321L16.4127 8.60705L14.4127 5.14295ZM17.1778 5.93289C17.1668 5.91347 17.1558 5.89417 17.1447 5.875L13.6806 7.875C13.6864 7.88499 13.6921 7.89492 13.6976 7.90479L17.1778 5.93289ZM17.1778 5.93286C16.8121 5.28751 16.3603 4.69305 15.8336 4.16637L13.0052 6.9948C13.2765 7.26612 13.5093 7.57235 13.6977 7.90481L17.1778 5.93286ZM15.8336 4.16637C15.0675 3.40029 14.1581 2.7926 13.1571 2.37799L11.6264 6.07351C12.142 6.28709 12.6106 6.60015 13.0052 6.9948L15.8336 4.16637ZM13.1571 2.37799C12.1562 1.96339 11.0834 1.75 10 1.75V5.75C10.5581 5.75 11.1108 5.85993 11.6264 6.07351L13.1571 2.37799ZM8 3.75V10H12V3.75H8Z"
        fill="#666666"
        mask="url(#path-2-inside-1)"
      />
    </svg>
  )
}

export default Mins10
