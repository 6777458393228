import React from 'react'

const Mins5 = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="9" stroke="#666666" strokeWidth="2" />
      <mask id="path-2-inside-1" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 10L13.125 4.58734C12.8892 4.45123 12.6444 4.33038 12.3918 4.22575C11.6335 3.91166 10.8208 3.75 10 3.75V10Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 10L13.125 4.58734C12.8892 4.45123 12.6444 4.33038 12.3918 4.22575C11.6335 3.91166 10.8208 3.75 10 3.75V10Z"
        fill="#666666"
      />
      <path
        d="M13.125 4.58734L14.8571 5.58734L15.857 3.85529L14.125 2.85529L13.125 4.58734ZM10 10H8V17.4641L11.7321 11L10 10ZM12.3918 4.22575L13.1571 2.37799L13.1571 2.37799L12.3918 4.22575ZM10 3.75V1.75H8V3.75H10ZM11.3929 3.58734L8.26795 9L11.7321 11L14.8571 5.58734L11.3929 3.58734ZM14.125 2.85529C13.8138 2.67562 13.4905 2.5161 13.1571 2.37799L11.6264 6.07351C11.7982 6.14466 11.9647 6.22684 12.125 6.31939L14.125 2.85529ZM13.1571 2.37799C12.1562 1.96339 11.0834 1.75 10 1.75V5.75C10.5581 5.75 11.1108 5.85993 11.6264 6.07351L13.1571 2.37799ZM8 3.75V10H12V3.75H8Z"
        fill="#666666"
        mask="url(#path-2-inside-1)"
      />
    </svg>
  )
}

export default Mins5
