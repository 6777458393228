import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'
import fetch from 'cross-fetch'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import * as Sentry from '@sentry/react'

export const createApolloClient = () => {
  const httpLink = new HttpLink({
    uri: '/graphql',
    credentials: 'include',
    fetch
  })

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(error => {
        Sentry.captureException(error)
        console.log(error)
      })
    }

    if (networkError) {
      Sentry.captureException(networkError)
      console.log(networkError)
    }
  })

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers
      }
    }
  })

  const client = new ApolloClient({
    link: authLink.concat(errorLink).concat(httpLink),
    cache: new InMemoryCache(),
    connectToDevTools: true
  })

  return client
}
