import React from 'react'

interface Props {
  color?: string
}

const CircleTick = ({ color = 'white' }: Props) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 25.2C20.1856 25.2 25.2 20.1856 25.2 14C25.2 7.81441 20.1856 2.8 14 2.8C7.81441 2.8 2.8 7.81441 2.8 14C2.8 20.1856 7.81441 25.2 14 25.2ZM14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
        fill={color}
      />
      <path
        d="M8.3999 14L12.2499 18.9L18.8999 8.39999"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  )
}

export default CircleTick
