import { useQuery } from '@apollo/client'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Flex, Image, Link, Text, Box } from 'theme-ui'
import GET_USERS from '../gql/GET_USERS'
import { UsersResult } from '../types'

const Header = () => {
  const { data } = useQuery<UsersResult>(GET_USERS)

  const user = data?.users && data.users[0] ? data.users[0] : null

  return (
    <Flex px={5} py={2} sx={{ width: '100%' }}>
      <Flex sx={{ flex: 1 }}>
        {/* 
        // @ts-ignore */}
        <Link as={RouterLink} variant="bold" to="/sessions" mr={3}>
          All sessions
        </Link>
        {/* 
        // @ts-ignore */}
        <Link as={RouterLink} variant="bold" to="/blog">
          Blog
        </Link>
      </Flex>
      <Flex sx={{ flex: 1, justifyContent: 'flex-end' }}>
        {user ? (
          <>
            <Text mr={2}>{`Hey, ${user.gh_name}`}</Text>
            <Link variant="bold" mr={2} href="/logout">
              Logout
            </Link>
            <Box>
              <Image
                width="24px"
                height="24px"
                mr={2}
                src={user.gh_pic}
                sx={{ borderRadius: '12px' }}
              />
            </Box>
          </>
        ) : (
          <Link
            href="#"
            variant="bold"
            onClick={event => {
              event.preventDefault()
            }}
          >
            Login
          </Link>
        )}
      </Flex>
    </Flex>
  )
}

export default Header
